import nerveLogo from './nerveLogo.png'
import nerveDarkLogo from './nerveLogoWhite.png'
import './Nav.css'

function Nav({ toggleDarkMode, theme }) {
  const navOptions = [
    // { text: "Analytics", link: "https://docs.nerve.fi/" },
    { text: 'Docs', link: 'https://docs.nerve.fi/' },
  ]
  return (
    <nav className="nerve-app__nav-container">
      <img src={theme === 'light' ? nerveLogo : nerveDarkLogo} />
      <ul className="nerve-app__list-container">
        <li>
          <i
            onClick={toggleDarkMode}
            class={theme === 'light' ? 'far fa-sun' : 'far fa-moon'}
          ></i>
        </li>
        {navOptions.map((navItem) => (
          <li>
            <a href={navItem.link} className="nerve-app__list-item">
              {navItem.text}
            </a>
          </li>
        ))}
      </ul>
      <a className="nerve-app__nav-launch-btn nerve-app__hide-element" href="https://app.nerve.fi">
        <button>Launch App</button>
      </a>
    </nav>
  )
}

export default Nav
