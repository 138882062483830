import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className="footer nerve-app__hide-element">
      <ul className="footer__socials">
        <li>
          <a href="https://discord.gg/waqfG39F4j" target="_blank">
            <i class="fab fa-discord"></i>
          </a>
        </li>
        <li>
          <a href="https://t.me/nervefinance" target="_blank">
            <i class="fab fa-telegram-plane"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/nervefinance" target="_blank">
            <i class="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://docs.nerve.fi/" target="_blank">
            <i class="fab fa-github"></i>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Footer
