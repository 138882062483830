import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.30s linear;  
  }

  .nerve-app__stats-right {
    border-left: 2px solid ${({ theme }) => theme.text};
  }

  .nerve-app__list-container a {
    color: ${({ theme }) => theme.text};
  }

  .nerve-app__nav-container button { 
    background: ${({ theme }) => theme.border}
  }

  .nerve-app__nav-launch-btn a {
    color: ${({ theme }) => theme.body};
  }

  
  `
